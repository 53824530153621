import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popoverastro',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverAstroComponent implements OnInit {
  selectedEvent: string = '';
  exp:any;
  showchrono:boolean;
  eventNames: string[] = [
    'Abortion', 'Accident', 'Aggression', 'Arrest/Jail', 'Award', 'Bail', 'Career', 'Change in Career', 
    'Child Birth', 'Cold Nature', 'Coming home', 'Depression', 'Divorce', 'Education', 'Health', 
    'Litigation', 'Litigation win', 'Love', 'Marriage', 'Property purchase', 'Property sale', 'Transfer', 
    'Travel', 'Vehicle Purchase', 'Vehicle sale'
  ];

   eventData = [
    { "event": "Abortion", "data": { "SlightNegative": "N/A", "Negative": "1,4,6,8,10,12,Mars,Rahu,ketu,saturn", "Positive": "2,5,9,11", "SlightPositive": "N/A" }},
    { "event": "Accident", "data": { "SlightNegative": "N/A", "Negative": "4,6,8/4,6,12/4,6,8,12", "Positive": "N/A", "SlightPositive": "N/A" }},
    { "event": "Aggression", "data": { "SlightNegative": "N/A", "Negative": "7,8,12 /8,12 and mars", "Positive": "N/A", "SlightPositive": "N/A" }},
    { "event": "Arrest/Jail", "data": { "SlightNegative": "N/A", "Negative": "2,8,12/3,8,12 , Rahu ,Ketu", "Positive": "N/A", "SlightPositive": "N/A" }},
    { "event": "Award", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12", "Positive": "10,11 ,Jup,venus", "SlightPositive": "N/A" }},
    { "event": "Bail", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12", "Positive": "10,11", "SlightPositive": "N/A" }},
    { "event": "Career", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12", "Positive": "10,11", "SlightPositive": "N/A" }},
    { "event": "Change in Career", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "5 and 9 ,rahu,ketu,sat,sun", "SlightPositive": "N/A" }},
    { "event": "Child Birth", "data": { "SlightNegative": "6,8,12", "Negative": "1,4,10", "Positive": "2,5,11,Jup", "SlightPositive": "9" }},
    { "event": "Cold Nature", "data": { "SlightNegative": "N/A", "Negative": "1,4,6,10", "Positive": "2,5,9,11", "SlightPositive": "N/A" }},
    { "event": "Coming home", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "2,4,11", "SlightPositive": "N/A" }},
    { "event": "Depression", "data": { "SlightNegative": "N/A", "Negative": "2,6,8/1,6,8", "Positive": "5,9/5,11/5,9,11", "SlightPositive": "N/A" }},
    { "event": "Divorce", "data": { "SlightNegative": "8,12", "Negative": "1,6,10 ,rahu,ketu,sat", "Positive": "2,5,7,9,11", "SlightPositive": "N/A" }},
    { "event": "Education", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12", "Positive": "2,4,5,9,11,jup,mer", "SlightPositive": "N/A" }},
    { "event": "Health", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12,satu", "Positive": "5,9,11", "SlightPositive": "N/A" }},
    { "event": "Litigation", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12,rahu,ketu,sat", "Positive": "10,11", "SlightPositive": "N/A" }},
    { "event": "Litigation win", "data": { "SlightNegative": "N/A", "Negative": "6,8/6,12/8,12", "Positive": "10,11", "SlightPositive": "N/A" }},
    { "event": "Love", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "5,8/5,12,Venus", "SlightPositive": "N/A" }},
    { "event": "Marriage", "data": { "SlightNegative": "N/A", "Negative": "1,6,10", "Positive": "2,7,11", "SlightPositive": "5,9" }},
    { "event": "Property purchase", "data": { "SlightNegative": "6,8/6,12/8,12", "Negative": "N/A", "Positive": "4,11,mars,sat", "SlightPositive": "N/A" }},
    { "event": "Property sale", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "3,5,10,Mars,sat", "SlightPositive": "N/A" }},
    { "event": "Transfer", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "3,9,12", "SlightPositive": "N/A" }},
    { "event": "Travel", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "3,9/3,12/9,12,rahu,ket,satu,sun", "SlightPositive": "7" }},
    { "event": "Vehicle Purchase", "data": { "SlightNegative": "6,8/6,12/8,12", "Negative": "N/A", "Positive": "4,11,Venus", "SlightPositive": "N/A" }},
    { "event": "Vehicle sale", "data": { "SlightNegative": "N/A", "Negative": "N/A", "Positive": "3,5,10,venus", "SlightPositive": "N/A" }}
  ];
  

  eventDetails: any;
  showaspect: boolean;
  snexp: string;
  negexp: string;
  posexp: string;
  spexp: string;
  showgems: boolean;

  onEventChange(ev) {

    this.selectedEvent = ev.target.value;
    this.changeexp()
  }

  constructor() {

    this.showchrono = false;
    this.showaspect = false;
    this.showgems = false;
   }

  ngOnInit() {}

showchrono1()
{
 this.showchrono = !this.showchrono;
}
showaspect1()
{
 this.showaspect = !this.showaspect;
}
showgems1()
{
 this.showgems = !this.showgems;
}
  compareWith(o1, o2) {
    console.log(o1,o2)
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

 

 
  changeexp()
  { 
    if(this.selectedEvent)
    {
      for(var i=0;i<this.eventData.length;i++)
      {
        if(this.selectedEvent == this.eventData[i].event)
        {
          this.snexp = "Slight Negative : " + this.eventData[i].data.SlightNegative;
          this.negexp = "Negative : " + this.eventData[i].data.Negative ;
          this.posexp = "Positive : " + this.eventData[i].data.Positive;
          this.spexp =  "Slight Positive : " + this.eventData[i].data.SlightPositive;
        }
      }
    }
  }

  
}
