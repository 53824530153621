import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/authguard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
     canActivate: [AuthGuard],
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'buygemstones',
     canActivate: [AuthGuard],
    loadChildren: () => import('./buy-gemstones/buy-gemstones.module').then( m => m.BuyGemstonesPageModule)
  },
  {
    path: 'vastu',
     canActivate: [AuthGuard],
    loadChildren: () => import('./vastu/vastu.module').then( m => m.VastuModule)
  },
  {
    path: 'vediccards',
     canActivate: [AuthGuard],
    loadChildren: () => import('./vediccards/vediccards.module').then( m => m.VediccardsPageModule)
  },
  {
    path: 'horaryhoroscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./horaryhoroscope/horaryhoroscope.module').then( m => m.HoraryHoroscopePageModule)
  },
  {
    path: 'bothhoroscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./bothhoroscope/bothhoroscope.module').then( m => m.BothHoroscopePageModule)
  },
  {
    path: 'horoscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./horoscope/horoscope.module').then( m => m.HoroscopePageModule)
  },
  {
    path: 'numerology',
     canActivate: [AuthGuard],
    loadChildren: () => import('./numerology/numerology.module').then( m => m.NumerologyPageModule)
  },
  {
    path: 'both',
     canActivate: [AuthGuard],
    loadChildren: () => import('./both/both.module').then( m => m.BothPageModule)
  },
  {
    path: 'matchmakinghoroscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./matchmakinghoroscope/matchmakinghoroscope.module').then( m => m.MatchmakingHoroscopePageModule)
  },
  {
    path: 'matchmakinghoroscopenumero',
     canActivate: [AuthGuard],
    loadChildren: () => import('./matchmakinghoroscopenumero/matchmakinghoroscope.module').then( m => m.MatchmakingHoroscopePageModule)
  },
  {
    path: 'home',
     canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'openbothhoroscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./openbothhoroscope/openbothhoroscope.module').then( m => m.OpenbothhoroscopePageModule)
  },
  {
    path: 'openhoroscope',
     canActivate: [AuthGuard],
    loadChildren: () => import('./openhoroscope/openhoroscope.module').then( m => m.OpenhoroscopePageModule)
  },
  {
    path: 'openhoroscopematch',
     canActivate: [AuthGuard],
    loadChildren: () => import('./openhoroscopematch/openhoroscopematch.module').then( m => m.OpenhoroscopematchPageModule)
  },
  {
    path: 'openhoroscopematchnumero',
     canActivate: [AuthGuard],
    loadChildren: () => import('./openhoroscopematchnumero/openhoroscopematch.module').then( m => m.OpenhoroscopematchPageModule)
  },
  {
    path: 'openhoraryhoroscope',
    loadChildren: () => import('./openhoraryhoroscope/openhoraryhoroscope.module').then( m => m.OpenHoraryhoroscopePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginModule)
  },
   { path: '**', redirectTo: 'home' },
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
