import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpResponse, HttpHeaders, HttpClient} from '@angular/common/http';
import { AuthGuard } from './services/authguard';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AstrologyAppService } from './services/astrology-app.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { CommonModule } from '@angular/common';  
import { PopoverAstroComponent } from './popoverastro/popover.component';
import { PopoverComponent } from './popover/popover.component';
@NgModule({
  declarations: [AppComponent , PopoverAstroComponent ,PopoverComponent],
  
  imports: [BrowserModule, CommonModule , IonicModule.forRoot(), AppRoutingModule, HttpClientModule],
  providers: [
    File,
    FileOpener,
   PDFGenerator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide : LocationStrategy , useClass: HashLocationStrategy},
{ 
        provide: HTTP_INTERCEPTORS,
        useClass: AstrologyAppService,
        multi: true
      },
     AuthGuard 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
