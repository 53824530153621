import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from './theme.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { PopoverComponent } from './popover/popover.component';
import { PopoverAstroComponent } from './popoverastro/popover.component';
import { AstrologyAppService } from './services/astrology-app.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isAlertOpen = false;
  alertButtons = ['Action'];
  user: any;
  xyz: any;

  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }
public themeColor = [
 { name: 'Default', class: 'default' },
 { name: 'Dark', class: 'dark-theme' },
 ]
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public selectTheme;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(public astroAppService:AstrologyAppService , private alertController: AlertController,public router:Router,private theme: ThemeService,public popoverController: PopoverController) {
   this.selectTheme = 'default';
    this.dynamicTheme(this.selectTheme)
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      this.user = JSON.parse(currentUser);
    }
    
    this.astroAppService.authenticate_userastrologer().subscribe(
      data=>
      {
        console.log(data);
        this.xyz =data;
        if(this.xyz.user)
        this.user = this.xyz.user;
      }
    ) 

  }


  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Something Went Wrong',
      subHeader: 'API CALL ERROR',
      message: 'Error Fetching Data',
      buttons: ['Ok'],
    });

    await alert.present();
  }
  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  async presentPopover1(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverAstroComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

   dynamicTheme(st) {
    this.theme.activeTheme(st);
    console.log("hi",this.selectTheme,st)

  }
  logout()
  {
    localStorage.clear();
  this.router.navigate(['/login'])
  }
  onClick(event){
    let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    systemDark.addListener(this.colorTest);
    if(event.detail.checked){
      document.body.setAttribute('data-theme', 'dark');
       this.theme.activeTheme('dark-theme');
    }
    else{
      document.body.setAttribute('data-theme', 'light');
      this.theme.activeTheme('default');
    }
    console.log(event);
  }

   colorTest(systemInitiatedDark) {
    if (systemInitiatedDark.matches) {
      document.body.setAttribute('data-theme', 'dark');   
    } else {
      document.body.setAttribute('data-theme', 'light');
    }
  }


  checkModuleAccess(...moduleNames: string[]): boolean {
    if (!this.user || !this.user.access) return false;

    return moduleNames.every((moduleName) =>
      this.user.access.some((module: any) => module.module === moduleName && module.isEnabled)
    );
  }

  onMenuOpen()
{
  console.log("hello");
    
  this.astroAppService.authenticate_userastrologer().subscribe(
    data=>
    {
      console.log(data);
      this.xyz =data;
      if(this.xyz.user)
      this.user = this.xyz.user;
    }
  ) 
}

}
